//Custom settings --
@import "variables";

//Bootstrap styles
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");
@import "node_modules/bootstrap/scss/bootstrap";

//Custom vendors
// @import "vendors/slick";
// @import "vendors/animate";


//Fonts

@import "../fonts/fontawesome/fontawesome";
@import "../fonts/fontawesome/solid";
@import "../fonts/fontawesome/brands";

//Component specific styles
@import "components/demo";
@import "components/type";


//page specific
@import "pages/home";

//Custom vendors

