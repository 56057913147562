//colors
//#152026
//#26A65D
//#035921
//#BF8665
//F24405


/* Color Theme Swatches in Hex */
.Riddec-|-Website-Design-|-IT-Company-1-hex { color: #152026; }
.Riddec-|-Website-Design-|-IT-Company-2-hex { color: #26A65D; }
.Riddec-|-Website-Design-|-IT-Company-3-hex { color: #035921; }
.Riddec-|-Website-Design-|-IT-Company-4-hex { color: #BF8665; }
.Riddec-|-Website-Design-|-IT-Company-5-hex { color: #F24405; }

$theme-colors: (
        "primary": blue,
        "dark": #000000
);

@import "node_modules/bootstrap/scss/bootstrap";

body{
  background-color: #152026;
  overflow: hidden;
  position: relative;
}

.mandala{
  position: absolute;
  z-index: 99;
  top: 0;
  right: -30%;
  width: 100%;
  max-width: 1050px;
  box-shadow: #aa0782;

  svg{
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.55));
  }

  @include media-breakpoint-down(md){
    top: -25%;
    right: -55%;
  }
}

.cover{
  height: 90vh;
  min-height: 500px;
  background-color: #152026;

  h2 {
    font-size: 1.5rem;
    font-style: italic;
    font-family: 'Libre Baskerville', serif;
    color: #ffffff;

    @include media-breakpoint-down(md){
      font-size: .75rem;
    }
  }
}


.logo{
  max-width: 350px;

  @include media-breakpoint-down(md){
    margin: 0 auto;
    max-width: 350px;
  }
}