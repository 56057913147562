@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");

$enable-rounded: false;

$border-radius: 1rem;

//// SPACING
//$spacer: 2.75rem !default;

//// THEMING: COLORS
$primary: #5b38e3;
$secondary: #F7D046;
$white: #FFFFFF;

$gray-base: #202020;
$gray-100: mix($gray-base, #ffffff, 5%);
$gray-200: mix($gray-base, #ffffff, 10%);
$gray-300: mix($gray-base, #ffffff, 20%);
$gray-400: mix($gray-base, #ffffff, 25%);
$gray-500: mix($gray-base, #ffffff, 40%);
$gray-600: mix($gray-base, #ffffff, 65%);
$gray-700: mix($gray-base, #ffffff, 80%);
$gray-800: mix($gray-base, #ffffff, 90%);
$gray-900: $gray-base;


//// THEMING: FONTS
$font-family-base: 'Inter', sans-serif;
$body-color: $gray-base;
$font-size-base: 1rem;
$headings-line-height: 1;
$font-size-xl: $font-size-base * 2.889;
$font-size-lg: $font-size-base * 1.556;
$font-size-md: $font-size-base * 1.333;
$font-size-sm: $font-size-base * .833;
$small-font-size: $font-size-sm;
$paragraph-margin-bottom: $font-size-base * 1.5;
$fa-font-path: "../fonts/webfonts" !default;

//// List inline padding
$list-inline-padding: 1rem;

//// THEMING: HEADINGS
$h1-font-size: $font-size-base *5;
$h2-font-size: $font-size-base * 3;
$h3-font-size: $font-size-base * 2;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1;
$headings-font-family: 'Libre Baskerville', serif;
$headings-color: $body-color;
$headings-font-weight: 900;

//// THEMING: NAVBAR
$navbar-light-color: $gray-900;
$navbar-nav-link-padding-x: 3.125rem / 2;
$navbar-padding-x: 15px;

//// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1500px) !default;

$container-max-widths: (
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1500px
);


//// THEMING: HR
$hr-border-color: #000 !default;
$border-color: #000 !default;

///THEMING: BUTTONS
$btn-font-weight: 600;
$btn-padding-y: 1.25rem;
$btn-padding-x:  1rem;